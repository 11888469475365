<template>
    <div class="bgm-content">
        <div style="width: 100%; margin-bottom: 10px;">
            <el-button type="primary" @click="addBgImage">添加背景图</el-button>
        </div>
        <el-table :data="tableData" style="width: 100%;" height="calc(100% - 82px)">
            <el-table-column label="序号" align="center" width="80px">
              <template #default="scope">
                {{indexVal(scope.$index)}}
              </template>
            </el-table-column>
            <el-table-column label="标题" prop="title" align="center"></el-table-column>
            <el-table-column label="背景素材" align="center">
                <template #default="scope">
                  <a :href="scope.row.url" target="_blank">查看</a>
                </template>
            </el-table-column>
            <el-table-column label="是否使用">
              <template #default="scope">
                  <el-switch v-model="scope.row.status" @change="switchEvt(scope.row)" />
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="create_time" align="center"></el-table-column>
            <el-table-column label="操作" width="200px" align="center">
                <template #default="scope">
                  <el-button type="success" :icon="Edit" @click="editBGimage(scope.row.background_id)" />
                  <el-button type="danger" plain :icon="Delete" @click="removeImgConfirm(scope.row.background_id)" />
                </template>
            </el-table-column>
        </el-table>
        <page-component :page="page" :total="total" :limit="limit" @change="pageChange" />
    </div>

</template>

<script setup>
import {onMounted, ref} from "vue";
import {bgmList} from "@/utils/apis";
import {ElMessage, ElMessageBox} from "element-plus";
import {config} from "@/utils/config"
import {Delete, Edit} from '@element-plus/icons-vue'
import {useRouter} from "vue-router";
import {deleteBgImage,bgimageChange} from "@/utils/apis";
import PageComponent from "@/components/common/PageComponent";

const tableData = ref([])
const page = ref(1)
const limit = ref(10)
const bgmLists = ref([])
const total = ref(0)
const bgimageList = async () => {
    let params = {
        page: page.value,
        pageSize: limit.value,
        paging: 1
    }
    bgmLists.value = []
    try {
        const {data, msg} = await bgmList(params)
        tableData.value = data.list.map((item) => {
            item.status = Number(item.status) === 1
            item.url = config.baseUrl + item.url
            bgmLists.value.push(item.url)
            return item
        })
        total.value = data.total
    }catch (e) {
       ElMessage.warning(e.message)
    }
}
const pageChange = (value) => {
    page.value = value
    bgimageList()
}
const indexVal = (index) => {
    return index + (limit.value * (page.value - 1)) + 1
}
const router = useRouter()
const addBgImage = () => {
  router.push({name: 'BGMAdd'})
}
const removeImgConfirm = (id) => {
  ElMessageBox.confirm(
      '确定删除该背景图，删除之后则无法恢复',
      '删除警告',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }
  ).then(() => {
      removebgImage(id)
  }).catch(() => { ElMessage.warning('已取消')})
}
const removebgImage = async (id) => {
    try {
      const {data, msg} = await deleteBgImage(id)
      ElMessage.success(msg)
      bgimageList()
    } catch (e) {
      if (typeof e === "string") {
        return ElMessage.warning(e)
      }
      ElMessage.warning(e.message)
    }
}
const editBGimage = (id) => {
    router.push({name: 'BGMEdit', query: {id}})
}

const switchEvt = async (row) => {
    try {
        const {data, msg} = await bgimageChange(row.background_id)
        ElMessage({
          type: 'success',
          message: msg,
          duration: 500,
          onClose: () => {
             bgimageList()
          }
        })
    } catch(e) {
        ElMessage.warning(e)
    }
    console.log('row', row);
}

onMounted(() => {
    bgimageList()
})

</script>

<style scoped lang="scss">
.bgm-content {
    &, & * {
      box-sizing: border-box;
    }
    padding: 10px;
    height: calc(100% - 60px);
}

</style>